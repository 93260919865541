/** @jsx jsx */
import { arrayOf, string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import renderRichText from '~/content/renderRichText'
import {
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'

const DISPLAY_NAME = 'CenteredTextHeroSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType.isRequired,
  heading: string.isRequired,
  links: arrayOf(LinkPropType),
}

interface PropTypes {
  body: ContentfulRichTextType,
  elementId?: string,
  heading: string,
  links?: LinkType[],
}

const Component = ({
  body,
  elementId,
  heading,
  links,
}: PropTypes) => (
  <Section id={elementId}>
    <IntroTransition>
      <div sx={{ textAlign: 'center' }}>
        <Container variant="containerMedium">
          <Heading
            as="h1"
            variant="headingHero"
          >
            {heading}
          </Heading>
          <div sx={{ marginTop: 3 }}>
            {renderRichText(body, { style: 'prospect' })}
          </div>
          {links?.length && (
            <ButtonList
              sx={{
                justifyContent: ['center', 'center'],
                marginTop: 3,
              }}
            >
              {links?.map(({ id, ...link }) => (
                <li key={id}>
                  {renderLink(link)}
                </li>
              ))}
            </ButtonList>
          )}
        </Container>
      </div>
    </IntroTransition>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
